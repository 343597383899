import React from "react"
import Layout from "../components/layout"

const SupportPage = ({ pageContext }) => (
  <Layout title="Support and Purchasing" navItems={pageContext.categories}>
    <div className="uk-section-xsmall">
      <div className="uk-container uk-container-large uk-text-left">
        <h1 className="uk-text-center">Support and Purchasing</h1>
        <p>&nbsp;</p>
        <p>
          Daikin North America is partnered with an extensive network of HVAC
          manufacturer rep firms across the United States and Canada. These rep
          firms have the ability to design, select, submit, procure, deliver,
          start-up and maintain Daikin VRV and split systems, and can normally
          package Daikin equipment with a long list of other HVAC manufacturers
          to provide full HVAC packages for buildings.
        </p>

        <p>
          For help within the regions that we (DXS) operate, please use the
          links below:
        </p>

        <p>
          <a
            href="https://dxseng.com/canada/contact-us/"
            target="_blank"
            className="uk-text-bold"
          >
            Ontario, Canada
          </a>{" "}
          (including the Ottawa / Gatineau region)
        </p>

        <p>
          <a
            href="https://dxseng.com/new-eng/contact-us/"
            target="_blank"
            className="uk-text-bold"
          >
            New England
          </a>{" "}
          (Massachusetts, New Hampshire, Rhode Island)
        </p>

        <p>
          <a
            href="https://dxseng.com/contact-us/"
            target="_blank"
            className="uk-text-bold"
          >
            Texas
          </a>
        </p>

        <p>
          For help outside of those regions, please use the{" "}
          <a
            href="http://www.daikinac.com/content/where-to-buy"
            target="_blank"
            className="uk-text-bold"
          >
            Daikin rep locator tool
          </a>{" "}
          for help. (Use the bottom right toolbox labelled “Find a
          Manufacturer’s Representative”
        </p>
      </div>
    </div>
  </Layout>
)

export default SupportPage
